module services {
    export module sales {
        export class salesOrderItemTrackingService implements interfaces.sales.ISalesOrderItemTrackingService{
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            remove(soiId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "SalesOrderItemTracking/Delete", {
                    soiId: soiId
                });
            }
        }
    }
    angular.module("app").service("salesOrderItemTrackingService", services.sales.salesOrderItemTrackingService);
}